import React, { useEffect, useState } from "react";
import { Row, Col, Button, Tab, Tabs, Form, Alert } from "react-bootstrap";
import {
  HandleChange, GetAllItems, CheckFormValidity, CheckFieldValidity,
  DefaultSelectValue, HandleAttachment, ClearAttachment
} from "../../utility/Form";
import CommonEditItemComp from "../common/Edit";
import Select from 'react-select';
import { set, isEmpty, get, find, filter, merge, sortBy } from "lodash";
import DatePicker from 'react-date-picker';
import ModalPopUp from "../common/modal/Modal";
import StatcTable from "../common/StaticTable";
import TextEditor from "../text-editor";
import CurrencyJson from "../../config/currency.json";
import GoogleMap from "../google-map/map";
import CurrencyFormat from 'react-currency-format';
import AreasJson from "../../config/areas.json";
import { isBrowser } from "../common/ggfx-client/utility";
import { getLoggedInUserOffices, getUser } from "../../utility/Auth";
import ConfirmOnExitComp from "../common/ConfirmOnExit";
import CommonTableComp from "../common/Table";

const EditFormControls = (props) => {
  const ImagesFieldToArray = (fieldName, images = []) => {
    let images_field = [];
    for (let index = 0; index < images.length; index++) {
      let imagesItemCopyObj = { 'field': fieldName, ...images[index] };
      delete imagesItemCopyObj.isUpdate;
      delete imagesItemCopyObj.isDelete;
      images_field.push(imagesItemCopyObj);
    }
    return images_field;
  }
  //Default
  //Default fields values
  const [DefaultFields, SetDefaultFields] = useState({
    price: null,
    bedroom: null,
    bathroom: null,
    reception: null,
    floorarea_min: null,
    floorarea_max: null,
    amenities: [],
    max_price: null,
    building: [],
    max_bedroom: null,
    developer_logo1: {},
    developer_banner_image: {}
  });

  const RentalStatusOptions = [
    { value: "Let", label: "Let" },
    { value: "Let Agreed", label: "Let Agreed" },
    { value: "PostPoned", label: "PostPoned" },
    { value: "To Let", label: "To Let" },
    { value: "Withdrawn", label: "Withdrawn" },
  ];
  const departments = [
    { value: "residential", label: "Residential" },
    { value: "commercial", label: "Commercial" },
  ]
  const searchTypes = [
    { value: "sales", label: "Sales" },
    { value: "lettings", label: "Lettings" },
  ]
  const SaleStatus = [
    { value: "Exchanged", label: "Exchanged" },
    { value: "For Sale", label: "For Sale" },
    { value: "PostPoned", label: "PostPoned" },
    { value: "Sold", label: "Sold" },
    { value: "Sale Agreed", label: "Sale Agreed" },
    { value: "Withdrawn", label: "Withdrawn" },
  ]

  const price_qualifier = [
    { value: "Asking price", label: "Asking price" },
    { value: "Guide price", label: "Guide price" },
    { value: "POA", label: "POA" },
    { value: "Offers in excess of", label: "Offers in excess of" },
    { value: "Offers in the region of", label: "Offers in the region of" },
  ]
  const pBERRatings = [
    { label: "No Min. BER", value: null },
    { label: "A1", value: "A1" },
    { label: "A2", value: "A2" },
    { label: "A3", value: "A3" },
    { label: "B1", value: "B1" },
    { label: "B2", value: "B2" },
    { label: "B3", value: "B3" },
    { label: "C1", value: "C1" },
    { label: "C2", value: "C2" },
    { label: "C3", value: "C3" },
    { label: "D1", value: "D1" },
    { label: "D2", value: "D2" },
    { label: "E1", value: "E1" },
    { label: "E2", value: "E2" },
    { label: "F", value: "F" },
    { label: "G", value: "G" },
  ]
  const BuildingsList = {
    residential: {
      sales: [
        { value: "Apartment", label: "Apartment" },
        { value: "Bungalow", label: "Bungalow" },
        { value: "Cottage", label: "Cottage" },
        { value: "Country House", label: "Country House" },
        { value: "Detached House", label: "Detached House" },
        { value: "Dormer", label: "Dormer" },
        { value: "Duplex", label: "Duplex" },
        { value: "End of Terrace", label: "End of Terrace" },
        { value: "Farm", label: "Farm" },
        { value: "Holiday Home", label: "Holiday Home" },
        { value: "House", label: "House" },
        { value: "Investment", label: "Investment" },
        { value: "Live-Work Unit", label: "Live-Work Unit" },
        { value: "Period House", label: "Period House" },
        { value: "Semi-Detached House", label: "Semi-Detached House" },
        { value: "Site", label: "Site" },
        { value: "Terraced House", label: "Terraced House" },
        { value: "Townhouse", label: "Townhouse" },
      ],
      lettings: [
        { value: "Apartment", label: "Apartment" },
        { value: "House", label: "House" },
        { value: "Studio", label: "Studio" },
      ]
    },
    commercial: {
      default: [
        { value: "Business", label: "Business" },
        { value: "Development Land", label: "Development Land" },
        { value: "Farm Land", label: "Farm Land" },
        { value: "Hotels & B+Bs", label: "Hotels & B+Bs" },
        { value: "Industrial", label: "Industrial" },
        { value: "Investment", label: "Investment" },
        { value: "Live-Work Unit", label: "Live-Work Unit" },
        { value: "Office", label: "Office" },
        { value: "Parking Space", label: "Parking Space" },
        { value: "Pubs & Restaurants", label: "Pubs & Restaurants" },
        { value: "Retail", label: "Retail" },
      ]
    }
  }

  const PriceQualifierList = {
    sales: [
      { value: "Asking price", label: "Asking price" },
      { value: "Guide price", label: "Guide price" },
      { value: "POA", label: "POA" },
      { value: "Offers in excess of", label: "Offers in excess of" },
      { value: "Offers in the region of", label: "Offers in the region of" },
    ],
    lettings: [
      { value: "pa", label: "Per Annum" },
      { value: "pcm", label: "Per Month" },
      { value: "pw", label: "Per Week" },
      { value: "POA", label: "POA" }
    ]
  }
  const floorarea_types = [
    { value: "squareFeet", label: "Square feet" },
    { value: "squareMetres", label: "Square meters" },
    { value: "Square yards", label: "Square yards" },
    { value: "Hectares", label: "Hectares" },
    { value: "Acres", label: "Acres" },
  ]

  const [dateValue, setDateValue] = useState(null);
  const [facilities, setFacilities] = useState([""]);
  const [highLightFeatures, setHighLightFeatures] = useState([""]);
  const [uploadedData, setUploadedData] = useState([]);
  const [allUploadedItems, setAllUploadedItems] = useState([]);
  const [tabKey, setTabKey] = useState("0")
  set(DefaultFields, 'country', "IE");
  const [property, setPropertyDetails] = useState(null)
  const [agentNotes, setAgentNotes] = useState([""]);
  const [currencyOption, setCurrencyOption] = useState();
  const [floorAreaType, setFloorAreaType] = useState();
  const [price, setPrice] = useState();
  const [max_price, setMaxPrice] = useState();
  const [pBERRating, setpBERRating] = useState();
  const [topBERRating, setToBERRating] = useState();
  const [amenities, setAmenities] = useState([]);
  const [videoTour, setVideoTour] = useState();
  const [virtuvalTour, setVirtuvalTour] = useState();
  const [officeCrmId, setOfficeCrmId] = useState('');
  const [isOfficeListLoaded, setIsOfficeListLoaded] = useState(false);
  const [negotiatorId, setNegotiatorId] = useState('');
  const [isNegotiatorListLoaded, setIsNegotiatorListLoaded] = useState(false);
  const [isChildReload, setIsChildReload] = useState(false);

  //Get building managers
  const GetSettings = async () => {
    try {
      const { data } = await GetAllItems('settings.getAll', { query: "_limit=-1" });
      setCurrencyOption(data?.results[0]?.currency)
      set(props, 'additionalParams.fields.currency', data?.results[0]?.currency);
    } catch (error) {
      console.log('EditFormControls GetSettings error', error);
    }
  };

  //Get property details
  const SetPropertyDetails = async (data) => {
    setPropertyDetails(data);
    setPrice(data.price?.toString() || "");
    setMaxPrice(data.max_price?.toString() || "")
    setDepartment(find(departments, (dp) => { return data.department === dp.value }))
    setSearchType(find(searchTypes, (dp) => { return data.search_type === dp.value }))
    setPriceQualifier(find(PriceQualifierList[data.search_type], (dp) => { return data.price_qualifier === dp.value }))
    setFloorAreaType(find(floorarea_types, (dp) => { return data.floorarea_type === dp.value }))
    setpBERRating(find(pBERRatings, (dp) => { return data.extras?.extrasField?.pBERRating === dp.value }))
    setToBERRating(find(pBERRatings, (dp) => { return data.extras?.extrasField?.to_pBERRating === dp.value }))
    let statusList = (data.search_type === 'sales') ? SaleStatus : RentalStatusOptions;
    setPropertyStatusList(statusList);
    setPropertyStatus(find(statusList, (dp) => { return data.status === dp.value }))
    let building_list = BuildingsList['residential']['sales'];
    if (data.department === "residential") {
      building_list = BuildingsList[data.department][data.search_type];
    }
    setBuildingTypesList(building_list);
    if (data.building?.length > 0) {
      setBuildingType(building_list.filter(bl => (data.building.indexOf(bl.value) > -1)))
    }
    HandleMultiSelect({ label: data?.county, value: data?.county }, 'county')
    HandleMultiSelect({ label: data?.sub_county, value: data?.sub_county }, 'sub_county')
    data?.town && HandleMultiSelect({ label: data?.town, value: data?.town }, 'town')
    if (data?.office_crm_id) {
      setOfficeCrmId(data?.office_crm_id);
    }
    if (data?.negotiator_id1) {
      setNegotiatorId(data?.negotiator_id1);
    }
    data?.available_from && setDateValue(new Date(data?.available_from))
    setRadioValues({ furnishing: data?.furnishing });
    setCheckboxValues({ is_featured_property: data?.is_featured_property })
    if (!isEmpty(data?.amenities) && data?.amenities?.length > 0 && Array.isArray(data?.amenities)) {
      let Facilities = data.amenities?.map(facility => { return { value: facility, label: facility } });
      setAmenities(Facilities)
      set(DefaultFields, `amenities`, data.amenities);
    }
    if (!isEmpty(data?.special) && data?.special?.length > 0 && Array.isArray(data?.special)) {
      setHighLightFeatures(data?.special)
    }
    if (!isEmpty(data?.room_details) && data?.room_details?.length > 0 && Array.isArray(data?.room_details)) {
      setroom_details(data?.room_details)
    }
    if (!isEmpty(data?.agent_notes)) {
      setAgentNotes(data?.agent_notes)
    }
    data.latitude && setLatitude(data.latitude);
    data.longitude && setLongitude(data.longitude);
    if (data.latitude && data.longitude) {
      setMapLoc({ lat: data.latitude, lng: data.longitude })
    }
    data.house_number && setHouseNumber(data.house_number);
    data.address1 && setAddress1(data.address1);
    data.post_code && setPostCode(data.post_code);
    data.display_address && setDisplayAddress(data.display_address);
    setMapActionType('lat-lng');
    if (data.video_tour?.length > 0) {
      setVideoTour(data.video_tour[0].url)
    }
    if (data.virtual_tour?.length > 0) {
      setVirtuvalTour(data.virtual_tour[0].url)
    }
    if (data?.extras?.floor) {
      HandleMultiSelect({ label: data.extras.floor, value: data.extras.floor }, 'floor')
    }
    //Set all media files
    setAllUploadedItems([...ImagesFieldToArray('images', data.images), ...ImagesFieldToArray('floorplan', data.floorplan),
    ...ImagesFieldToArray('epc', data.epc), ...ImagesFieldToArray('brochure', data.brochure)]);
    if(data?.developer_logo1) {
      setSelectBoxFieldErrors3({ ...selectBoxFieldErrors3, ...{"developer_logo1": { hasError: false } } });
      setDeveloperLogoUrl(data.developer_logo1.url);
    } else {
      setSelectBoxFieldErrors3({ ...selectBoxFieldErrors3, ...{"developer_logo1": { hasError: true } } });
    }
    if(data?.developer_banner_image) {
      setSelectBoxFieldErrors3({ ...selectBoxFieldErrors3, ...{"developer_banner_image": { hasError: false } } });
      setDeveloperBannerImageUrl(data.developer_banner_image.url);
    } else {
      setSelectBoxFieldErrors3({ ...selectBoxFieldErrors3, ...{"developer_banner_image": { hasError: true } } });
    }
    if(data?.developer_logo2) {
      setSelectBoxFieldErrors3({ ...selectBoxFieldErrors3, ...{"developer_logo2": { hasError: false } } });
      setDeveloperLogoUrl2(data.developer_logo2.url);
    } else {
      setSelectBoxFieldErrors3({ ...selectBoxFieldErrors3, ...{"developer_logo2": { hasError: true } } });
    }
    //Default fields
    set(DefaultFields, 'price', data?.price);
    set(DefaultFields, 'max_price', data?.max_price);
    set(DefaultFields, 'bedroom', data?.bedroom);
    set(DefaultFields, 'max_bedroom', data?.max_bedroom);
    set(DefaultFields, 'bathroom', data?.bathroom);
    set(DefaultFields, 'floorarea_min', data?.floorarea_min);
    set(DefaultFields, 'reception', data?.reception);
    set(DefaultFields, 'floorarea_max', data?.floorarea_max);
    set(DefaultFields, 'images', data?.images);
    set(DefaultFields, 'floorplan', data?.floorplan);
    set(DefaultFields, 'epc', data?.epc);
    set(DefaultFields, 'brochure', data?.brochure);
    set(DefaultFields, 'crm_negotiator_id', data.crm_negotiator_id);
    set(DefaultFields, 'crm_negotiator_email', data.crm_negotiator_email);
    set(DefaultFields, 'building', data.building);
    set(DefaultFields, 'developer_logo1', data.developer_logo1);
    set(DefaultFields, 'developer_banner_image', data.developer_banner_image);
  };

  //Get facilities
  const GetFecilities = async () => {
    try {
      const { data } = await GetAllItems('facilities.getAll', { query: "_limit=-1&_sort=facility:ASC" });
      setFacilities(data?.results?.map(facility => { return { value: facility.facility, label: facility.facility } }))
    } catch (error) {
      console.log('EditFormControls GetBuildingManagers error', error);
    }
  };

  //Get Areas
  const [countyJSON, setCountyJSON] = useState([]);
  const [county, setCounty] = useState();
  const [subCountyJSON, setSubCountyJSON] = useState([]);
  const [subCounty, setSubCounty] = useState('');
  const [townJSON, setTownJSON] = useState([]);
  const [town, setTown] = useState('');
  const [isFormSaveTriggered, setIsFormSaveTriggered] = useState(false);

  const GetAreas = async () => {
    try {
      let countyList = [];
      filter(AreasJson, function (area) {
        if (area.level === 1) {
          countyList.push({ label: area.name, value: area.name })
          return 1;
        }
      })
      setCountyJSON(countyList);
    } catch (error) {
      console.log('AddFormControls GetAreas error', error);
    }
  };

  //Get Offices
  const [officesList, setOfficesList] = useState([]);
  const [offices, setOffices] = useState([]);
  const [office, setOffice] = useState();
  const [loggedInUserOffices, setLoginUserOffices] = useState(getLoggedInUserOffices());
  const [loginUser, setLoginUser] = useState(getUser());
  const GetOffices = async () => {
    try {
      const { data } = await GetAllItems('offices.getMyHomeOffices',
        { query: "_limit=-1" });
      let Offices = [];
      data.map(office => {
        if (office['my_home_id']) {
          if (loginUser.hasSuperAdminRole) {
            Offices.push({
              value: office['my_home_id'],
              label: `${office['Office_Name']} ${office['Branch_Location'] ? (`(${office['Branch_Location']})`) : ""}`,
              id: office.id,
              isDisabled: (!office['Publish']) || (!office.published_at)
            });
          } else {
            Offices.push({
              value: office['my_home_id'],
              label: `${office['Office_Name']} ${office['Branch_Location'] ? (`(${office['Branch_Location']})`) : ""}`,
              id: office.id,
              isDisabled: (isEmpty(find(loggedInUserOffices, function (userOffice) {
                return userOffice['my_home_id'] === office['my_home_id']
              })) || (!office['Publish']) || (!office.published_at))
            });
          }
        }
        return 1;
      });
      setOffices(Offices)
      setOfficesList(data);
      setIsOfficeListLoaded(true);
    } catch (error) {
      console.log('EditFormControls GetOffices error', error);
    }
  };

  //Get Teams for negotiator
  const [negotiators, setNegotiators] = useState([]);
  const [negotiator, setNegotiator] = useState();
  const GetNegotiators = async (officeId) => {
    try {
      // Get the office by id to list negotiators
      let Negotiators = [];
      officesList.filter(office => {
        if (office.id === officeId) {
          Negotiators = office['Teams'].map(negotiator => {
            return {
              value: negotiator['crm_id'], label: negotiator['Name'], Email: negotiator['Email'],
              isDisabled: (!negotiator['Publish']) || (!negotiator.published_at) || (!negotiator['crm_id'])
            }
          });
        }
        return 1;
      });
      setIsNegotiatorListLoaded(true);
      setNegotiators(Negotiators);
    } catch (error) {
      console.log('AddFormControls GetNegotiators error', error);
    }
  };

  useEffect(() => {
    // GetBuildings();
    if (!isEmpty(props.editItem)) {
      SetPropertyDetails(props.editItem);
    }
  }, [props.editItem]);

  useEffect(() => {
    GetSettings();
    GetFecilities();
    GetAreas();
    GetOffices();
  }, []);

  useEffect(() => {
    if (isOfficeListLoaded) {
      const OfficeObjTemp = find(offices, function (office) {
        return office.value === officeCrmId;
      })
      HandleMultiSelect(OfficeObjTemp, 'office_crm_id');
    }
  }, [isOfficeListLoaded, officeCrmId]);

  useEffect(() => {
    if (negotiatorId) {
      const NegotiatorObjTemp = find(negotiators, function (negotiator) {
        return negotiator.value === negotiatorId;
      })
      HandleMultiSelect(NegotiatorObjTemp, 'negotiator_id1');
    }
  }, [isNegotiatorListLoaded, negotiatorId]);


  useEffect(() => {
    let UploadedItems = [...allUploadedItems].filter(item => !isEmpty(item));
    if (uploadedData.isUpdate || uploadedData.isDelete) {
      let itemsByField = [], itemsByOtherFields = [];
      UploadedItems.forEach(element => {
        if (element.field === uploadedData.field)
          return itemsByField.push(element);
        itemsByOtherFields.push(element);
      });
      if (uploadedData.isUpdate) {
        itemsByField = itemsByField.map((item, index) => {
          if (index === uploadedData.index) {
            const ItemMerged = { ...item, ...uploadedData };
            delete ItemMerged.isUpdate;
            return ItemMerged;
          }
          return item;
        });
      } else if (uploadedData.isDelete) {
        itemsByField = itemsByField.filter((item, index) => {
          if (index === uploadedData.index) {
            return false;
          }
          return item;
        });
      }
      UploadedItems = itemsByField.concat(itemsByOtherFields);
    } else if (!isEmpty(uploadedData) && !isEmpty(uploadedData.items)) {
      //concat 2 arrays
      UploadedItems = UploadedItems.concat(uploadedData.items);
    }
    UploadedItems = sortBy(UploadedItems, ['field', 'order']);
    set(DefaultFields, `${uploadedData.field}`, [...UploadedItems]
      .filter(item => (item.field === uploadedData.field)));
    setAllUploadedItems([...UploadedItems].filter(item => !isEmpty(item)));
  }, [uploadedData]);

  const [mapLoc, setMapLoc] = useState({ lat: 51.509865, lng: -0.118092 });
  const [latitude, setLatitude] = useState();
  const [longitude, setLongitude] = useState();
  const [address1, setAddress1] = useState();
  const [houseNumber, setHouseNumber] = useState();
  const [postCode, setPostCode] = useState();
  const [displayAddress, setDisplayAddress] = useState();
  const [mapActionType, setMapActionType] = useState('lat-lng');

  const getMapLocation = (loc) => {
    setMapLoc(loc);
    setLatitude(loc.lat);
    setFormError({ ...formError, ...{ latitude: { hasError: false } } });
    setLongitude(loc.lng);
    setFormError({ ...formError, ...{ longitude: { hasError: false } } });
    setDisplayAddress(loc.display_address);
  }

  const addHighLightFeatures = (index) => {
    if (highLightFeatures[index]) {
      setHighLightFeatures([...highLightFeatures, ""]);
    } else {
      alert('Please fill!');
    }
  }

  const removeHighLightFeatures = (index) => {
    let newHighLightFeatures = [...highLightFeatures]
    newHighLightFeatures.splice(index, 1);
    setHighLightFeatures(newHighLightFeatures);
  }

  const handleHighLightFeaturesChange = (event, index) => {
    let newHighLightFeatures = [...highLightFeatures];
    newHighLightFeatures[index] = event.target.value;
    setHighLightFeatures(newHighLightFeatures);
  }

  const handleHighLightFeaturesKP = (event, index) => {
    if (event.charCode === 13) {
      event.preventDefault();
      addHighLightFeatures(index);
    }
  }

  const handleAgentNotesChange = (event, index) => {
    let newAgentNotes = [...agentNotes];
    newAgentNotes[index] = event.target.value;
    setAgentNotes(newAgentNotes);
  }

  //Rooms 
  const [room_details, setroom_details] = useState([{
    name: "",
    dimensions: "",
    description: "",
  }]);
  const addroom_details = (index) => {
    if (room_details[index]) {
      const NewRoomDetail = {
        name: "",
        dimensions: "",
        description: "",
      };
      setroom_details([...room_details, NewRoomDetail]);
    } else {
      alert('Please fill!');
    }
  }

  const removeroom_details = (index) => {
    let newRooms = [...room_details];
    newRooms.splice(index, 1);
    setroom_details(newRooms);
  }

  const handleroom_detailsChange = (event, index, name) => {
    let newRooms = [...room_details];
    newRooms[index][name] = event.target.value;
    setroom_details(newRooms);
  }

  const handleroom_detailsKP = (event, index) => {
    if (event.charCode === 13) {
      event.preventDefault();
      setroom_details(index);
    }
  }

  const getUploadedData = (uploadedDataFromModal) => {
    setUploadedData(uploadedDataFromModal);
  }

  const [showModalImages, setShowModalImages] = useState(false);
  const [showModalFloorplan, setShowModalFloorplan] = useState(false);
  const [showModalEPC, setShowModalEPC] = useState(false);
  const [showModalBrochure, setShowModalBrochure] = useState(false);

  const showModalImagesFunc = () => {
    setShowModalImages(true);
  }

  const setShowModalImagesFunc = () => {
    setShowModalImages(false);
  }

  const showModalFloorplanFunc = () => {
    setShowModalFloorplan(true);
  }

  const setShowModalFloorplanFunc = () => {
    setShowModalFloorplan(false);
  }

  const showModalEPCFunc = () => {
    setShowModalEPC(true);
  }

  const setShowModalEPCFunc = () => {
    setShowModalEPC(false);
  }

  const showModalBrochureFunc = () => {
    setShowModalBrochure(true);
  }

  const setShowModalBrochureFunc = () => {
    setShowModalBrochure(false);
  }

  // This state used to store form errors
  //form error has form error and each field error status but now field level error
  const [formError, setFormError] = useState({});
  const CheckFormFieldValidity = (event, isSelectBox = false) => {
    const FieldError = CheckFieldValidity(event, isSelectBox);
    setFormError({ ...formError, ...FieldError });
  }

  const HandleChangeFunc = (event) => {
    HandleChange(event);
    if (event?.target?.name === 'crm_id') {
      setCrmId(event?.target?.value);
    }
    if (event?.target?.name === 'latitude') {
      setLatitude(event?.target?.value);
      setMapLoc({ ...mapLoc, latitude: event?.target?.value });
      setMapActionType('lat-lng');
    }
    if (event?.target?.name === 'longitude') {
      setLongitude(event?.target?.value);
      setMapActionType('lat-lng');
      setMapLoc({ ...mapLoc, longitude: event?.target?.value });
    }
    if (event?.target?.name === 'display_address') {
      setDisplayAddress(event?.target?.value);
    }
    if (event?.target?.value) {
      if ((event?.target?.name !== 'price') || (event?.target?.name !== 'max_price'))
        delete DefaultFields[event?.target?.name];
      else if (event?.target?.name === 'house_number') {
        setHouseNumber(event.target.value);
        setMapActionType('address');
      }
      else if (event?.target?.name === 'address1') {
        setAddress1(event.target.value);
        setMapActionType('address');
      }
      else if (event?.target?.name === 'post_code') {
        setPostCode(event.target.value);
        setMapActionType('address');
      }
    } else {
      DefaultFields[event?.target?.name] = null;
    }
    CheckFormFieldValidity(event);
  }

  const HandleBlurFunc = (event) => {
    if (event?.target?.name === 'house_number') {
      setHouseNumber(event?.target?.value || '');
      setMapActionType('address');
    } else if (event?.target?.name === 'address1') {
      setAddress1(event?.target?.value || '')
      setMapActionType('address');
    } else if (event?.target?.name === 'post_code') {
      setPostCode(event?.target?.value || '')
      setMapActionType('address');
    }
  }

  const DecodePriceValue = (priceObj) => {
    set(DefaultFields, 'price', priceObj.floatValue);
    SetDefaultFields({ ...DefaultFields, price: priceObj.floatValue });
    SetDefaultFields({ ...DefaultFields, price: priceObj.floatValue });
  }

  const DecodeMaxPriceValue = (maxpriceObj) => {
    set(DefaultFields, 'max_price', maxpriceObj.floatValue);
    SetDefaultFields({ ...DefaultFields, max_price: maxpriceObj.floatValue });
    SetDefaultFields({ ...DefaultFields, max_price: maxpriceObj.floatValue });
  }

  const [developerLogoUrl, setDeveloperLogoUrl] = useState('');
  const [developerBannerImageUrl, setDeveloperBannerImageUrl] = useState('');
  const [developerLogoUrl2, setDeveloperLogoUrl2] = useState('');
  const GetUploadedUrl = async (event, fieldId) => {
    try {
      const UploadedResult = await HandleAttachment(event, { ...props, fieldId: fieldId });
      set(props, `additionalParams.fields.${UploadedResult['field'] || 'media'}`, { url: UploadedResult.url, id: UploadedResult.id })
      if (UploadedResult['field'] === 'developer_logo1') {
        setDeveloperLogoUrl(UploadedResult.url);
        set(DefaultFields, 'developer_logo1', UploadedResult);
      } else if(UploadedResult['field'] === 'developer_logo2'){
        setDeveloperLogoUrl2(UploadedResult.url);
        set(DefaultFields, 'developer_logo2', UploadedResult);
      } else {
        setDeveloperBannerImageUrl(UploadedResult.url);
        set(DefaultFields, 'developer_banner_image', UploadedResult);
      }
      if (UploadedResult.url) {
        setSelectBoxFieldErrors3({ ...selectBoxFieldErrors3, ...{ [UploadedResult.field]: { hasError: false } } });
      } else {
        setSelectBoxFieldErrors3({ ...selectBoxFieldErrors3, ...{ [UploadedResult.field]: { hasError: true } } });
      }
    } catch (error) {
      console.log('GetUploadedUrl error', error, event, props)
    }
  }

  const FormSubmitFunc = async (event, additionalParams = {}) => {
    try {
      if (isEmpty(get(props, 'additionalParams.fields.currency'))) {
        set(props, 'additionalParams.fields.currency', currencyOption)
      }
      const Result = await props.HandleSubmitFunc(event, {
        formId: 'edit-new-development-form',
        ...additionalParams,
        fields: {
          ...DefaultFields,
          street_name: address1, district: subCounty?.value,
          town: county?.value, area: town?.value
        }
      });
      let selectBoxErrorObjTemp = {};
      if (isEmpty(selectBoxFieldErrors)) {
        selectBoxErrorObjTemp = { ...SelectBoxFieldDefaultErrors };
        setSelectBoxFieldErrors(selectBoxErrorObjTemp);
      } else if (find(selectBoxFieldErrors, function (sboxerror) {
        return (sboxerror.hasError)
      })) {
        selectBoxErrorObjTemp = selectBoxFieldErrors;
      }
      if (get(Result, 'formSubmit.hasError')) {
        setFormError(Result.formSubmit.error);
      }
    } catch (error) {
      console.log('FormSubmitFunc error', error);
    }
  }

  const MoveToTab = async (event, key, additionalParams = {}) => {
    setIsFormSaveTriggered(true);
    const ChildNodes = document.getElementsByClassName('tab-pane active')[0];
    const TabError = CheckFormValidity(ChildNodes.getElementsByClassName('form-control'),
      { isSubmit: false, isCustom: true });
    //Check any field has error
    let tabHasError = false;
    const TabErrorKeys = Object.keys(TabError),
      TabErrorKeysLength = TabErrorKeys.length;
    for (let index = 0; index < TabErrorKeysLength; index++) {
      tabHasError = TabError[TabErrorKeys[index]]?.hasError;
      if (tabHasError) {
        setFormError(TabError);
        break;
      }
    }
    //Check selectbox va;idations
    let selectBoxErrorObjTemp = {};
    if (tabKey === "0") {
      selectBoxErrorObjTemp = { ...selectBoxFieldErrors };
      if (isEmpty(selectBoxFieldErrors)) {
        selectBoxErrorObjTemp = { ...SelectBoxFieldDefaultErrors };
        setSelectBoxFieldErrors(selectBoxErrorObjTemp);
      } else if (!isEmpty(find(selectBoxFieldErrors, function (sboxerror) {
        return (sboxerror.hasError)
      }))) {
        selectBoxErrorObjTemp = selectBoxFieldErrors;
        setSelectBoxFieldErrors(selectBoxErrorObjTemp);
      }
      if (!priceQualifier?.value) {
        let selectBoxErrorObjTemp1 = { ...selectBoxErrorObjTemp, price_qualifier: { hasError: true } }
        setSelectBoxFieldErrors(selectBoxErrorObjTemp1);
        selectBoxErrorObjTemp = { ...selectBoxErrorObjTemp1 };
      } else {
        let selectBoxErrorObjTemp1 = { ...selectBoxErrorObjTemp, price_qualifier: { hasError: false } }
        setSelectBoxFieldErrors(selectBoxErrorObjTemp1);
        selectBoxErrorObjTemp = { ...selectBoxErrorObjTemp1 };
      }
      if (office?.value) {
        if ((negotiators.length > 0) && (!negotiator?.value)) {
          let selectBoxErrorObjTemp1 = { ...selectBoxErrorObjTemp, negotiator_id1: { hasError: true } }
          setSelectBoxFieldErrors(selectBoxErrorObjTemp1);
          selectBoxErrorObjTemp = { ...selectBoxErrorObjTemp1 };
        } else {
          let selectBoxErrorObjTemp1 = { ...selectBoxErrorObjTemp, negotiator_id1: { hasError: false } }
          setSelectBoxFieldErrors(selectBoxErrorObjTemp1);
          selectBoxErrorObjTemp = { ...selectBoxErrorObjTemp1 };
        }
      }
      if (buildingType) {
        if (buildingType.length > 0) {
          let selectBoxErrorObjTemp1 = { ...selectBoxErrorObjTemp, building: { hasError: false } }
          setSelectBoxFieldErrors(selectBoxErrorObjTemp1);
          selectBoxErrorObjTemp = { ...selectBoxErrorObjTemp1 };
        } else {
          let selectBoxErrorObjTemp1 = { ...selectBoxErrorObjTemp, building: { hasError: true } }
          setSelectBoxFieldErrors(selectBoxErrorObjTemp1);
          selectBoxErrorObjTemp = { ...selectBoxErrorObjTemp1 };
        }
      }
    } else if (tabKey === "1") {
      selectBoxErrorObjTemp = { ...selectBoxFieldErrors2 };
      if (isEmpty(selectBoxFieldErrors2)) {
        selectBoxErrorObjTemp = { ...SelectBoxFieldDefaultErrors2 };
        //Check fields valid/invalid
        let selectBoxErrorObjTempObjCopy = Object.keys(selectBoxErrorObjTemp).filter(field => {
          let isInValid = true;
          switch (field) {
            case 'floorarea_type':
              if (floorAreaType?.value)
                isInValid = false;
              break;
            case 'description':
              if (props?.editItem?.description)
                isInValid = false;
              break;
            default:
              isInValid = true;
          }
          if (isInValid) {
            return field;
          }
        })
        selectBoxErrorObjTemp = { ...selectBoxErrorObjTempObjCopy };
        setSelectBoxFieldErrors2(selectBoxErrorObjTemp);
      } else if (!isEmpty(find(merge(SelectBoxFieldDefaultErrors2, selectBoxFieldErrors2), function (sboxerror) {
        return (sboxerror.hasError)
      }))) {
        selectBoxErrorObjTemp = selectBoxFieldErrors2;
        setSelectBoxFieldErrors2(selectBoxErrorObjTemp);
      }
    } else if (tabKey === '2') {
      selectBoxErrorObjTemp = { ...selectBoxFieldErrors3 };
      if (isEmpty(selectBoxErrorObjTemp)) {
        selectBoxErrorObjTemp = { ...SelectBoxFieldDefaultErrors3 };
        setSelectBoxFieldErrors3(selectBoxErrorObjTemp);
      } else if (!isEmpty(find(merge(SelectBoxFieldDefaultErrors3, selectBoxFieldErrors3), function (sboxerror) {
        return (sboxerror.hasError)
      }))) {
        selectBoxErrorObjTemp = merge(SelectBoxFieldDefaultErrors3, selectBoxFieldErrors3);
        setSelectBoxFieldErrors3(selectBoxErrorObjTemp);
      }
      if(developerLogoUrl) {
        setSelectBoxFieldErrors3({...selectBoxErrorObjTemp, ...{"developer_logo1": { hasError: false}}});
      } else
        setSelectBoxFieldErrors3({...selectBoxErrorObjTemp, ...{"developer_logo1": { hasError: true}}});
      if(developerLogoUrl2) {
        setSelectBoxFieldErrors3({...selectBoxErrorObjTemp, ...{"developer_logo2": { hasError: false}}});
      } else
        setSelectBoxFieldErrors3({...selectBoxErrorObjTemp, ...{"developer_logo2": { hasError: true}}});
      if(developerBannerImageUrl) {
        setSelectBoxFieldErrors3({...selectBoxErrorObjTemp, ...{"developer_banner_image": { hasError: false}}});
      } else 
        setSelectBoxFieldErrors3({...selectBoxErrorObjTemp, ...{"developer_banner_image": { hasError: true}}});
    }
    console.log('selectBoxErrorObjTemp', selectBoxErrorObjTemp)
    if ((!tabHasError) && (isEmpty(find(selectBoxErrorObjTemp, function (serror) {
      return serror.hasError;
    })))) {
      if (additionalParams.isSubmit) {
        try {
          await FormSubmitFunc(event, additionalParams);
          setTabKey(key);
          setIsFormSaveTriggered(false);
          setIsChildReload(!isChildReload);
        } catch (error) {
          console.log('additionalParams.isSave', error);
        }
      } else {
        setTabKey(key);
        setIsFormSaveTriggered(false);
      }
    }
    MoveToTopOfPage();
  };

  const MoveToTopOfPage = () => {
    if (isBrowser()) {
      window.scrollTo(0, 0)
    }
  }

  const SaveCurrentTab = (event) => {
    event.preventDefault();
    event.stopPropagation();
    let TabInc = (parseInt(tabKey) + 1).toString();
    let SubmitConfig = {
      isSubmit: true,
    };
    if (tabKey === '6') {
      TabInc = '0';
      SubmitConfig = {
        isSubmit: true,
        isPageRedirect: true,
        publish: true,
        parentPath: props.parentPath,
        pageRedirectUrl: `/${props.parentPath}/list`,
        enableValidation: true
      }
    }
    MoveToTab(event, TabInc, { ...SubmitConfig });
  }

  const [buildingType, setBuildingType] = useState([]);
  const [floor, setFloor] = useState(DefaultSelectValue);
  const [priceQualifier, setPriceQualifier] = useState();
  const [department, setDepartment] = useState();
  const [searchType, setSearchType] = useState();
  const [propertyStatus, setPropertyStatus] = useState();
  const [propertyStatusList, setPropertyStatusList] = useState([])
  const [buildingTypesList, setBuildingTypesList] = useState([]);
  const [crmId, setCrmId] = useState('');

  //Select box validations
  const SelectBoxFieldDefaultErrors = {
    // department: {
    //   hasError: true,
    //   type: 'required'
    // },
    // search_type: {
    //   hasError: true,
    //   type: 'required'
    // },
    status: {
      hasError: true,
      type: 'required'
    },
    building: {
      hasError: true,
      type: 'required'
    },
    office_crm_id: {
      hasError: true,
      type: 'required'
    },
    negotiator_id1: {
      hasError: false,
      type: 'required'
    },
    price_qualifier: {
      hasError: false,
      type: 'required'
    }
  };
  const [selectBoxFieldErrors, setSelectBoxFieldErrors] = useState({});

  const SelectBoxFieldDefaultErrors2 = {
    floorarea_type: {
      hasError: true,
      type: 'required'
    },
    description: {
      hasError: true,
      type: 'required'
    }
  };
  const [selectBoxFieldErrors2, setSelectBoxFieldErrors2] = useState({});

  const SelectBoxFieldDefaultErrors3 = {
    // developer_logo1: {
    //   hasError: true,
    //   type: 'required'
    // },
    // developer_banner_image: {
    //   hasError: true,
    //   type: 'required'
    // }
  };
  const [selectBoxFieldErrors3, setSelectBoxFieldErrors3] = useState({});

  const HandleMultiSelect = (event, name, copyInAddParam) => {
    if (copyInAddParam) {
      const SelectedOptions = event.map(option => option.value)
      set(DefaultFields, `${name}`, SelectedOptions);
    }
    //SetValidation 
    //Single select validation
    if (name !== 'floorarea_type') {
      if (SelectBoxFieldDefaultErrors[name]) {
        if (event?.value) {
          setSelectBoxFieldErrors({ ...selectBoxFieldErrors, ...{ [name]: { hasError: false } } });
        } else {
          setSelectBoxFieldErrors({ ...selectBoxFieldErrors, ...{ [name]: { hasError: true } } });
        }
      }
    } else {
      if (SelectBoxFieldDefaultErrors2[name]) {
        if (event?.value) {
          setSelectBoxFieldErrors2({ ...selectBoxFieldErrors2, ...{ [name]: { hasError: false } } });
        } else {
          setSelectBoxFieldErrors2({ ...selectBoxFieldErrors2, ...{ [name]: { hasError: true } } });
        }
      }
    }
    switch (name) {
      case 'building':
        if (!isEmpty(event)) {
          setSelectBoxFieldErrors({ ...selectBoxFieldErrors, ...{ "building": { hasError: false } } });
        } else {
          setSelectBoxFieldErrors({ ...selectBoxFieldErrors, ...{ "building": { hasError: true } } });
        }
        setBuildingType(event);
        break;
      case 'floor':
        setFloor(event);
        break;
      case 'price_qualifier':
        setPriceQualifier(event);
        setFormError({ ...formError, ...{ price: { hasError: ((event.value !== 'POA') && (!price)), required: true, msg: 'POA only excepted' } } });
        break;
      case 'currency':
        set(DefaultFields, 'currency', event);
        setCurrencyOption(event);
        break;
      case 'department':
        setBuildingTypesList([]);
        setDepartment(event);
        const BuildingListTemp1 = event.value ? (searchType?.value ?
          (BuildingsList[event.value][searchType.value] ?
            BuildingsList[event.value][searchType.value] : BuildingsList[event.value]['default']) : []) : [];
        setBuildingTypesList(BuildingListTemp1)
        setBuildingType([])
        if (event.value) {
          setSelectBoxFieldErrors({
            ...selectBoxFieldErrors,
            ...{
              building: { hasError: (true) }
            }
          });
        }
        break;
      case 'search_type':
        setBuildingTypesList([]);
        setBuildingType([])
        setPriceQualifier();
        setPropertyStatus('');
        setPropertyStatusList((event?.value === 'sales') ? SaleStatus :
          (event?.value === 'lettings' ? RentalStatusOptions : []));
        const BuildingListTemp2 = event.value ? (department?.value ? (
          BuildingsList[department.value][event.value] ?
            BuildingsList[department.value][event.value] : BuildingsList[department.value]['default']) : []) : [];
        setBuildingTypesList(BuildingListTemp2);
        if (event.value) {
          setSelectBoxFieldErrors({
            ...selectBoxFieldErrors,
            ...{
              status: { hasError: (true) }, price_qualifier: { hasError: (true) },
              search_type: { hasError: false }, building: { hasError: (true) }
            }
          });
        }
        setSearchType(event);
        break;
      case 'status':
        setPropertyStatus(event);
        break;
      case 'county':
        setCounty(event);
        setMapActionType('address');
        setTown('');
        setTownJSON([]);
        setSubCounty('');
        setSubCountyJSON([]);
        //update town/city/area list
        let areaListTemp = [];
        if (event.value) {
          filter(AreasJson, function (area) {
            //Need change TODO
            if (area.level === 2) {
              if (area.parent === event.value) {
                areaListTemp.push({ value: area.name, label: area.name });
              }
            }
            return 1;
          })
        }
        setSubCountyJSON(areaListTemp);
        break;
      case 'sub_county':
        setTown('');
        setMapActionType('address');
        let areaListTemp1 = [];
        if (event.value) {
          filter(AreasJson, function (area) {
            //Need change TODO
            if (area.level === 3) {
              if (area.parent === event.value) {
                areaListTemp1.push({ value: area.name, label: area.name });
              }
            }
          })
        }
        setTownJSON(areaListTemp1);
        setSubCounty(event);
        break;
      case 'town':
        setTown(event);
        setMapActionType('address');
        set(DefaultFields, 'area', event.value);
        break;
      case 'office_crm_id':
        setOffice(event);
        setNegotiators([]);
        setNegotiator();
        //Get Negotiator call
        if (event?.id) {
          GetNegotiators(event.id);
          setSelectBoxFieldErrors(merge(selectBoxFieldErrors, { office_crm_id: { hasError: false } }));
        }
        if (negotiators.length > 0) {
          setSelectBoxFieldErrors({ ...selectBoxFieldErrors, ...{ negotiator_id1: { hasError: true } } });
        } else {
          setSelectBoxFieldErrors({ ...selectBoxFieldErrors, ...{ negotiator_id1: { hasError: false } } });
        }
        break;
      case 'negotiator_id1':
        setNegotiator(event);
        if (event?.value) {
          setSelectBoxFieldErrors({
            ...selectBoxFieldErrors,
            ...{ negotiator_id1: { hasError: false } }
          });
        }
        set(DefaultFields, 'crm_negotiator_id', event);
        set(DefaultFields, 'crm_negotiator_email', event?.Email);
        break;
      case 'amenities':
        setAmenities(event);
        break;
      case 'floorarea_type':
        setFloorAreaType(event);
        break;
      default:
        break;
    }
  }

  const [checkboxValues, setCheckboxValues] = useState({});
  const HandleCheckBoxValue = (event) => {
    let checkBoxValueTemp = { [event.target.name]: event.target.checked };
    if ((event.target.name === 'is_enabled') && (!checkBoxValueTemp['is_enabled'])) {
      checkBoxValueTemp['is_show_on_website'] = false;
      checkBoxValueTemp['is_show_on_portal'] = false;
      checkBoxValueTemp['is_show_on_disposals_list'] = false;
    }
    setCheckboxValues({ ...checkboxValues, ...checkBoxValueTemp });
  }

  const [radioValues, setRadioValues] = useState({});
  const HandleRedioValue = (event) => {
    let RadioTempValues = {};
    set(RadioTempValues, event.target.name, event.target.value);
    setRadioValues({ ...radioValues, ...RadioTempValues });
  }

  const editorChange = (content, editor, fieldName) => {
    if (SelectBoxFieldDefaultErrors2[fieldName]) {
      if (content) {
        setSelectBoxFieldErrors2({ ...selectBoxFieldErrors2, ...{ [fieldName]: { hasError: false } } });
      } else {
        setSelectBoxFieldErrors2({ ...selectBoxFieldErrors2, ...{ [fieldName]: { hasError: true } } });
      }
    }
  }

  const selectedChildProperties = ({ selectedRows }) => {
    console.log('selectedRows', selectedRows)
    set(DefaultFields, `child_properties`, selectedRows.map(property => {
      return property.id
    }));
  }

  return (
    <>
      <Alert key="primary" variant="primary">
        <b>Display Address</b> : <u>{displayAddress}</u>
      </Alert>
      <Form id="edit-new-development-form" noValidate onSubmit={(event) => SaveCurrentTab(event)}>
        <section className="agent-tabs">
          {
            isFormSaveTriggered
            && ((find((tabKey === '0' ? selectBoxFieldErrors : (tabKey === '1' ? selectBoxFieldErrors2 : selectBoxFieldErrors3)), function (sboxerror) {
              return (sboxerror.hasError)
            })) || (find(formError, function (error) {
              return (error.hasError)
            }))) && <Alert key="danger" variant="danger">
              Highlighted fields are required
            </Alert>
          }
          <Tabs
            defaultActiveKey={"0"}
            transition={false}
            id="noanim-tab-example"
            className="mb-3"
            activeKey={tabKey}
            onSelect={(k) => MoveToTab({}, k)}
          >
            <Tab eventKey={"0"} title="Overview">
              <Row>
                <Col lg={12} className="d-flex justify-content-end">
                  <div className="text-end ms-3">
                    <Button type="submit" className="btn-primary-dashboard">Update</Button>
                  </div>
                </Col>
              </Row>
              <Row>
                <input type="hidden" name="crm_provider" value="property_uploader" />
                <input type="hidden" name="search_type" value="sales" />
                <input type="hidden" name="department" value="newdevelopments" />
                <Col lg={6} className="mb-4">
                  <div className="form-wrapper">
                    <label>Property Status *</label>
                    <div
                      className={`custom-selectbox-form selectbox-dropdown ${isFormSaveTriggered && selectBoxFieldErrors?.status?.hasError ? ' pu-has-control-error' : ''}`}>
                      <Select
                        name="status"
                        options={SaleStatus}
                        placeholder="Select status"
                        classNamePrefix={"select-opt"}
                        isSearchable={true}
                        onChange={(event) => HandleMultiSelect(event, 'status')}
                        value={propertyStatus}
                      />
                    </div>
                  </div>
                </Col>
                <Col lg={6} className="mb-4">
                  <div className="form-wrapper">
                    <label>Unit Types *</label>
                    <div
                      className={`custom-selectbox-form selectbox-dropdown ${isFormSaveTriggered && selectBoxFieldErrors?.building?.hasError ? ' pu-has-control-error' : ''}`}>
                      <Select
                        name="building"
                        options={BuildingsList['residential']['sales']}
                        placeholder="Select building"
                        classNamePrefix={"select-opt"}
                        isSearchable={true}
                        isMulti={true}
                        onChange={(event) => HandleMultiSelect(event, 'building', true)}
                        value={buildingType}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6} className="mb-4">
                  <div className="form-wrapper">
                    <label>Reference ID *</label>
                    <input type="text"
                      className={`form-control${isFormSaveTriggered && formError?.crm_id?.hasError ? ' pu-has-control-error' : ''}`}
                      name="crm_id"
                      placeholder="Enter reference id"
                      defaultValue={props?.editItem?.crm_id}
                      disabled={true}
                      id="crm_id"
                      onChange={(event) => HandleChangeFunc(event)}
                      required={true} />
                  </div>
                </Col>
                <Col lg={6} className="mb-4">
                  <div className="form-wrapper">
                    <label>Office *</label>
                    <div
                      className={`custom-selectbox-form selectbox-dropdown ${isFormSaveTriggered && selectBoxFieldErrors?.office_crm_id?.hasError ? ' pu-has-control-error' : ''}`}>
                      <Select
                        name="office_crm_id"
                        options={offices}
                        placeholder="Select office"
                        classNamePrefix={"select-opt"}
                        isSearchable={true}
                        onChange={(event) => HandleMultiSelect(event, 'office_crm_id')}
                        value={office}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              {
                (negotiators.length > 0) && <Row>
                  <Col lg={6} className="mb-4">
                    <div className="form-wrapper">
                      <label>Negotiator *</label>
                      <div
                        className={`custom-selectbox-form selectbox-dropdown ${isFormSaveTriggered && selectBoxFieldErrors?.negotiator_id1?.hasError ? ' pu-has-control-error' : ''}`}>
                        <Select
                          name="negotiator_id1"
                          options={negotiators}
                          placeholder="Select negotiator"
                          classNamePrefix={"select-opt"}
                          isSearchable={true}
                          onChange={(event) => HandleMultiSelect(event, 'negotiator_id1')}
                          value={negotiator}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              }
              <Row>
                <Col lg={6} className="mb-4">
                  <div className="form-wrapper">
                    <label>County </label>
                    <div
                      className="custom-selectbox-form selectbox-dropdown">
                      <Select
                        name="county"
                        options={countyJSON}
                        placeholder="Select county"
                        classNamePrefix={"select-opt"}
                        isSearchable={true}
                        value={county}
                        onChange={(event) => HandleMultiSelect(event, 'county')}
                      />
                    </div>
                  </div>
                </Col>
                <Col lg={6} className="mb-4">
                  <div className="form-wrapper">
                    <label>District/ Area </label>
                    <div
                      className="custom-selectbox-form selectbox-dropdown">
                      <Select
                        name="sub_county"
                        options={subCountyJSON}
                        placeholder="Select sub county"
                        classNamePrefix={"select-opt"}
                        isSearchable={true}
                        onChange={(event) => HandleMultiSelect(event, 'sub_county')}
                        value={subCounty}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              {
                townJSON.length > 1 && (
                  <Row>
                    <Col lg={6} className="mb-4">
                      <div className="form-wrapper">
                        <label>Town / City </label>
                        <div
                          className="custom-selectbox-form selectbox-dropdown">
                          <Select
                            name="town"
                            options={townJSON}
                            placeholder="Select town/city"
                            classNamePrefix={"select-opt"}
                            isSearchable={true}
                            onChange={(event) => HandleMultiSelect(event, 'town')}
                            value={town}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                )
              }
              <Row>
                <Col lg={6} className="mb-4">
                  <div className="form-wrapper">
                    <label>Development Name </label>
                    <input type="text"
                      className="form-control"
                      name="house_number"
                      placeholder="Enter development name"
                      id="house_number"
                      defaultValue={props?.editItem?.house_number}
                      onChange={(event) => HandleChangeFunc(event)}
                      onBlur={(event) => HandleBlurFunc(event)}
                    />
                  </div>
                </Col>
                <Col lg={6} className="mb-4">
                  <div className="form-wrapper">
                    <label>Street Name </label>
                    <input type="text"
                      className="form-control"
                      defaultValue={props?.editItem?.address1}
                      name="address1"
                      id="address1"
                      placeholder="Enter street name"
                      onChange={(event) => HandleChangeFunc(event)}
                      onBlur={(event) => HandleBlurFunc(event)}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6} className="mb-4">
                  <div className="form-wrapper">
                    <label>Postcode </label>
                    <input type="text"
                      className="form-control"
                      defaultValue={props?.editItem?.post_code}
                      name="post_code"
                      id="postcode"
                      placeholder="Enter postcode"
                      onChange={(event) => HandleChangeFunc(event)}
                      onBlur={(event) => HandleBlurFunc(event)}
                    />
                  </div>
                </Col>
                <Col lg={6} className="mb-4">
                  <div className="form-wrapper">
                    <label>Display Address </label>
                    <input type="text"
                      className="form-control"
                      name="display_address"
                      id="display_address"
                      placeholder="Enter display address"
                      value={displayAddress}
                      onChange={(event) => HandleChangeFunc(event)}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6} className="mb-4">
                  <div className="form-wrapper">
                    <label>Latitude </label>
                    <input type="text"
                      className="form-control"
                      name="latitude"
                      id="latitude"
                      placeholder="Enter latitude"
                      defaultValue={props?.editItem?.latitude}
                      onChange={(event) => HandleChangeFunc(event)}
                      value={latitude}
                    />
                  </div>
                </Col>
                <Col lg={6} className="mb-4">
                  <div className="form-wrapper">
                    <label>Longitude </label>
                    <input type="text"
                      className="form-control"
                      name="longitude"
                      id="longitude"
                      defaultValue={props?.editItem?.longitude}
                      placeholder="Enter longitude"
                      onChange={(event) => HandleChangeFunc(event)}
                      value={longitude}
                    />
                  </div>
                </Col>
              </Row>
              {
                (
                  (county?.value && subCounty?.value && (townJSON.length === 0 ? true : town?.value) &&
                    houseNumber && address1 && postCode)
                  || (
                    mapLoc.lat && mapLoc.lng
                  )
                ) && <Row>
                  <Col lg={12}>
                    <p>Change the position of the map marker by moving the map.</p>
                    <div className="main-wrapper-map">
                      {
                        <GoogleMap getMapLocation={getMapLocation}
                          mapLoc={{
                            lat: latitude ? parseFloat(latitude) : mapLoc.lat,
                            lng: longitude ? parseFloat(longitude) : mapLoc.lng
                          }}
                          inputAddress=
                          {county?.value && subCounty?.value && (townJSON.length === 0 ? true : town?.value) &&
                            houseNumber && address1 && postCode ?
                            `${houseNumber}, ${address1}, ${town.value ? `${town?.value}, ${subCounty?.value}` : subCounty?.value}, ${county?.value}, ${postCode}` : ''}
                          actionType={mapActionType}
                        />
                      }
                    </div>
                    <input type="hidden" name="latitude" value={mapLoc.lat || 51.509865} />
                    <input type="hidden" name="longitude" value={mapLoc.lng || -0.118092} />
                  </Col>
                </Row>
              }
              <Row>
                <Col lg={3} className="mb-4">
                  <div className="form-wrapper">
                    {/* Sale status */}
                    <label>Price Qualifier *</label>
                    <div
                      className={`custom-selectbox-form selectbox-dropdown ${isFormSaveTriggered && selectBoxFieldErrors?.price_qualifier?.hasError ? ' pu-has-control-error' : ''}`}>
                      <Select
                        name="price_qualifier"
                        options={price_qualifier}
                        placeholder="Select price qualifier"
                        classNamePrefix={"select-opt"}
                        isSearchable={true}
                        onChange={(event) => HandleMultiSelect(event, 'price_qualifier')}
                        value={priceQualifier}
                      />
                    </div>
                  </div>
                </Col>
                <Col lg={1}>
                  <label>Currency</label>
                  <div className="custom-selectbox-form selectbox-dropdown">
                    <Select
                      name="currency"
                      options={CurrencyJson}
                      placeholder="Select currency"
                      classNamePrefix={"select-opt"}
                      isSearchable={false}
                      value={currencyOption}
                      onChange={(event => HandleMultiSelect(event, 'currency'))}
                      isDisabled={true}
                    />
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="form-wrapper">
                    <label>Price {priceQualifier?.value === 'POA' ? '' : '*'}</label>
                    <CurrencyFormat displayType={'input'} thousandSeparator={true}
                      name="price"
                      value={price}
                      placeholder="Enter price"
                      className={`form-control${isFormSaveTriggered && formError?.price?.hasError ? ' pu-has-control-error' : ''}`}
                      onValueChange={(values) => {
                        DecodePriceValue(values)
                        setPrice(DecodePriceValue(values))
                      }}
                      onChange={(event) => HandleChangeFunc(event)}
                      required={priceQualifier?.value !== 'POA'}
                    />
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="form-wrapper">
                    <label>Max Price </label>
                    <CurrencyFormat displayType={'input'} thousandSeparator={true}
                      name="max_price"
                      value={max_price}
                      placeholder="Enter max price"
                      className={`form-control${isFormSaveTriggered && formError?.max_price?.hasError ? ' pu-has-control-error' : ''}`}
                      onValueChange={(values) => {
                        DecodeMaxPriceValue(values)
                        setMaxPrice(DecodeMaxPriceValue(values))
                      }}
                      onChange={(event) => HandleChangeFunc(event)}
                      required={false}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6} className="mb-4">
                  <div className="form-wrapper">
                    <label>Available from</label>
                    <div className="form-datepicker">
                      <DatePicker
                        format="dd-MM-yyyy"
                        name="available_from"
                        onChange={setDateValue}
                        value={dateValue}
                        dayPlaceholder="dd"
                        monthPlaceholder="mm"
                        yearPlaceholder="yyyy"
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6} className="d-flex">
                  <ConfirmOnExitComp {...props} />
                </Col>
                <Col lg={6} className="d-flex justify-content-end">
                  <Button className="btn-primary-dashboard" onClick={(event) => MoveToTab(event, "1")}>Next</Button>
                  <div className="text-end ms-3">
                    <Button type="submit" className="btn-primary-dashboard">Update</Button>
                  </div>
                </Col>
              </Row>
            </Tab>
            <Tab eventKey={"1"} title="Property Details">
              <Row>
                <Col lg={12} className="d-flex justify-content-end">
                  <div className="text-end ms-3">
                    <Button type="submit" className="btn-primary-dashboard">Update</Button>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6} className="mb-4">
                  <div className="form-wrapper">
                    <label>No. of Bedrooms *</label>
                    <input type="number" className={`form-control${isFormSaveTriggered && formError?.bedroom?.hasError ? ' pu-has-control-error' : ''}`}
                      name="bedroom"
                      min="0"
                      placeholder="Enter bedroom count"
                      id="bedroom"
                      defaultValue={props?.editItem?.bedroom}
                      onChange={(event) => HandleChangeFunc(event)}
                      required={true} />
                  </div>
                </Col>
                <Col lg={6} className="mb-4">
                  <div className="form-wrapper">
                    <label>No. of Bedrooms(Max) </label>
                    <input type="number"
                      className={`form-control`}
                      name="max_bedroom"
                      min="0"
                      placeholder="Enter max bedroom count"
                      defaultValue={props?.editItem?.max_bedroom}
                      onChange={(event) => HandleChangeFunc(event)} />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={4} className="mb-4">
                  <div className="form-wrapper">
                    <label>Size(Min) *</label>
                    <input type="number"
                      className={`form-control${isFormSaveTriggered && formError?.floorarea_min?.hasError ? ' pu-has-control-error' : ''}`}
                      name="floorarea_min"
                      id="floorarea_min"
                      placeholder="Enter square footage min size"
                      required={true}
                      defaultValue={props?.editItem?.floorarea_min}
                      onChange={(event) => HandleChangeFunc(event)} />
                  </div>
                </Col>
                <Col lg={4} className="mb-4">
                  <div className="form-wrapper">
                    <label>Size(Max)</label>
                    <input type="number" className={`form-control${isFormSaveTriggered && formError?.floorarea_max?.hasError ? ' pu-has-control-error' : ''}`}
                      name="floorarea_max"
                      id="floorarea_max"
                      defaultValue={props?.editItem?.floorarea_max}
                      placeholder="Enter square footage max size"
                      onChange={(event) => HandleChangeFunc(event)} />
                  </div>
                </Col>
                <Col lg={4} className="mb-4">
                  <div className="form-wrapper">
                    <label>Units/ Measurements Type *</label>
                    <div
                      className={`custom-selectbox-form selectbox-dropdown ${isFormSaveTriggered && selectBoxFieldErrors2?.floorarea_type?.hasError ? ' pu-has-control-error' : ''}`}>
                      <Select
                        name="floorarea_type"
                        options={floorarea_types}
                        placeholder="Select units/ measurements type"
                        classNamePrefix={"select-opt"}
                        isSearchable={true}
                        value={floorAreaType}
                        onChange={event => HandleMultiSelect(event, 'floorarea_type')}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6} className="mb-4">
                  <div className="form-wrapper">
                    <label>BER Rating(From)</label>
                    <div className="custom-selectbox-form selectbox-dropdown">
                      <Select
                        name="extras.extrasField.pBERRating"
                        options={pBERRatings}
                        placeholder="Select rating"
                        classNamePrefix={"select-opt"}
                        isSearchable={true}
                        value={pBERRating}
                        onChange={event => setpBERRating(event)}
                      />
                    </div>
                  </div>
                </Col>
                <Col lg={6} className="mb-4">
                  <div className="form-wrapper">
                    <label>BER Rating(To)</label>
                    <div className="custom-selectbox-form selectbox-dropdown">
                      <Select
                        name="extras.extrasField.to_pBERRating"
                        options={pBERRatings}
                        placeholder="Select rating"
                        classNamePrefix={"select-opt"}
                        isSearchable={true}
                        value={topBERRating}
                        onChange={event => setToBERRating(event)}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={12} className="mb-4">
                  <div className="form-wrapper">
                    <label>Description *</label>
                    <div
                      className={`${selectBoxFieldErrors2?.description?.hasError ? 'text-editor-error' : ''}`}>
                      <TextEditor name="description"
                        defaultValue={property?.description}
                        additionalParams={props.additionalParams}
                        editorChange={(content, editor) => editorChange(content, editor, 'description')} />
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={10} className="mb-4">
                  <div className="form-wrapper">
                    <label>Highlight Features</label>
                  </div>
                  <div className="d-flex justify-content-start btn-wrapper">
                    <div className="mb-0 w-100">
                      {
                        highLightFeatures?.map((element, i) => (
                          <div key={i} className="mb-4 d-flex justify-content-start">
                            <div className="form-wrapper w-100">
                              <input type="text" className={`form-control`} autoFocus={i > 0}
                                name={`special[${i}]`}
                                onChange={(event) => handleHighLightFeaturesChange(event, i)}
                                placeholder="Enter highlight feature"
                                onKeyPress={(event) => handleHighLightFeaturesKP(event, i)}
                                value={element} />
                            </div>
                            <div className="d-flex justify-content-between btn-add-delete">
                              {(highLightFeatures.length === (i + 1)) &&
                                <button type="button" className="btn-none" onClick={() => addHighLightFeatures(i)}>
                                  <i className="icon icon-add-circle"></i>
                                </button>
                              }
                              {highLightFeatures.length > 1 &&
                                <button type="button" className="btn-none" onClick={() => removeHighLightFeatures(i)}>
                                  <i className="icon icon-delete-circle"></i>
                                </button>
                              }
                            </div>
                          </div>
                        ))
                      }
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6} className="mb-4">
                  <div className="form-wrapper">
                    <label>Video URL</label>
                    <input type="text" className="form-control"
                      name="video_tour[0].url"
                      id="video_tour"
                      defaultValue={videoTour}
                      placeholder="Enter video tour url" />
                  </div>
                </Col>
                <Col lg={6} className="mb-4">
                  <div className="form-wrapper">
                    <label>Virtual Tour URL</label>
                    <input type="text" className="form-control"
                      name="virtual_tour[0].url"
                      id="virtual_tour"
                      defaultValue={virtuvalTour}
                      placeholder="Enter virtual tour url" />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6} className="d-flex">
                  <ConfirmOnExitComp {...props} />
                  <div className="ms-3">
                    <Button className="btn-primary-dashboard" onClick={(event) => MoveToTab(event, "0")}>Previous</Button>
                  </div>
                </Col>
                <Col lg={6} className="d-flex justify-content-end">
                  <Button className="btn-primary-dashboard" onClick={(event) => MoveToTab(event, "2")}>Next</Button>
                  <div className="text-end ms-3">
                    <Button type="submit" className="btn-primary-dashboard">Update</Button>
                  </div>
                </Col>
              </Row>
            </Tab>
            <Tab eventKey={"2"} title="Images">
              <Row>
                <Col lg={5} className="mb-4">
                  <div className="d-flex justify-content-between flex-wrap">
                    <div className="form-wrapper w-100">
                      <label>Development Logo *</label>
                      {/* NOTE: Every input file field shoud have name field it mainly used for upload the file in specific path */}
                      <input type="file" name="developer_logo1" className="form-control file-attach"
                        onChange={(event) => GetUploadedUrl(event, 'custom-file1')} id="custom-file1"
                        placeholder="Click to attach" />
                    </div>
                    {
                      developerLogoUrl &&
                      <a target="_blank" href={developerLogoUrl}>{developerLogoUrl}</a>
                    }
                  </div>
                </Col>
                <Col lg={1} className="mb-4">
                  <Button className="btn btn-default" onClick={() => {
                    ClearAttachment('custom-file1');
                    setDeveloperLogoUrl('');
                  }}>Clear</Button>
                </Col>
                <Col lg={5} className="mb-4">
                  <div className="d-flex justify-content-between flex-wrap">
                    <div className="form-wrapper w-100">
                      <label>Developments Banner Image *</label>
                      {/* NOTE: Every input file field shoud have name field it mailyb used for upload the file in specific path */}
                      <input type="file" name="developer_banner_image" className="form-control file-attach"
                        onChange={(event) => GetUploadedUrl(event, 'custom-file2')} id="custom-file2"
                        placeholder="Click to attach" />
                    </div>
                    {
                      developerBannerImageUrl &&
                      <a target="_blank" href={developerBannerImageUrl}>{developerBannerImageUrl}</a>
                    }
                  </div>
                </Col>
                <Col lg={1} className="mb-4">
                  <Button className="btn btn-default" onClick={() => {
                    ClearAttachment('custom-file2');
                    setDeveloperBannerImageUrl('');
                  }}>Clear</Button>
                </Col>
              </Row>
              <Row>
                <Col lg={5} className="mb-4">
                  <div className="d-flex justify-content-between flex-wrap">
                    <div className="form-wrapper w-100">
                      <label>Developer Logo *</label>
                      {/* NOTE: Every input file field shoud have name field it mailyb used for upload the file in specific path */}
                      <input type="file" name="developer_logo2" className="form-control file-attach"
                        onChange={(event) => GetUploadedUrl(event, 'custom-file3')} id="custom-file3"
                        placeholder="Click to attach" />
                    </div>
                    {
                      developerLogoUrl2 &&
                      <a target="_blank" href={developerLogoUrl2}>{developerLogoUrl2}</a>
                    }
                  </div>
                </Col>
                <Col lg={1} className="mb-4">
                  <label> </label>
                  <Button className="btn btn-default" onClick={() => {
                    ClearAttachment('custom-file3');
                    setDeveloperLogoUrl2('');
                  }}>Clear</Button>
                </Col>
              </Row>
              <div className="d-flex justify-content-between align-items-center mb-3">
                <p>The order of the assets below is the order they will be shown on the website.</p>
                <div className="text-end">
                  <Button className="btn-primary-dashboard" onClick={() => showModalImagesFunc()}>Add</Button>
                </div>
              </div>
              {/* ModalPopUp */}
              {
                showModalImages && <ModalPopUp
                  modalShow={showModalImages}
                  getUploadedData={getUploadedData}
                  modalAction={'upload'}
                  setModalShowFunc={setShowModalImagesFunc}
                  uploadedDataFieldName="images"
                  parentPath="new-developments"
                  format_text=".png, .jpg, .jpeg, .webp"
                  accept_format={{
                    'image/*': ['.jpeg', '.png', '.jpg', '.webp']
                  }}
                />
              }
              {/* UploadedItemsTable */}
              <StatcTable
                menu="New Developments"
                parentPath="file"
                allItems={allUploadedItems.filter(item => (item.field === 'images'))}
                getUploadedData={getUploadedData}
              />
              <Row>
                <Col lg={6} className="d-flex">
                  <ConfirmOnExitComp {...props} />
                  <div className="ms-3">
                    <Button className="btn-primary-dashboard" onClick={(event) => MoveToTab(event, "1")}>Previous</Button>
                  </div>
                </Col>
                <Col lg={6} className="d-flex justify-content-end">
                  <Button className="btn-primary-dashboard" onClick={(event) => MoveToTab(event, "3")}>Next</Button>
                  <div className="text-end ms-3">
                    <Button type="submit" className="btn-primary-dashboard">Update</Button>
                  </div>
                </Col>
              </Row>
            </Tab>
            <Tab eventKey={"3"} title="Floorplan">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <p>The order of the assets below is the order they will be shown on the website.</p>
                <div className="text-end">
                  <Button className="btn-primary-dashboard" onClick={() => showModalFloorplanFunc()}>Add</Button>
                </div>
              </div>
              {/* ModalPopUp */}
              {
                showModalFloorplan && <ModalPopUp
                  modalShow={showModalFloorplan}
                  getUploadedData={getUploadedData}
                  modalAction={'upload'}
                  setModalShowFunc={setShowModalFloorplanFunc}
                  uploadedDataFieldName="floorplan"
                  parentPath="new-developments"
                  format_text=".png, .jpg, .jpeg, .webp"
                  accept_format={{
                    'image/*': ['.jpeg', '.png', '.jpg', '.webp']
                  }}
                />
              }
              {/* UploadedItemsTable */}
              <StatcTable
                menu="New Developments"
                parentPath="file"
                allItems={allUploadedItems.filter(item => (item.field === 'floorplan'))}
                getUploadedData={getUploadedData}
              />
              <Row>
                <Col lg={6} className="d-flex">
                  <ConfirmOnExitComp {...props} />
                  <div className="ms-3">
                    <Button className="btn-primary-dashboard" onClick={(event) => MoveToTab(event, "2")}>Previous</Button>
                  </div>
                </Col>
                <Col lg={6} className="d-flex justify-content-end">
                  <Button className="btn-primary-dashboard" onClick={(event) => MoveToTab(event, "4")}>Next</Button>
                  <div className="text-end ms-3">
                    <Button type="submit" className="btn-primary-dashboard">Update</Button>
                  </div>
                </Col>
              </Row>
            </Tab>
            <Tab eventKey={"4"} title="BER">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <p>The order of the assets below is the order they will be shown on the website.</p>
                <div className="text-end">
                  <Button className="btn-primary-dashboard" onClick={() => showModalEPCFunc()}>Add</Button>
                </div>
              </div>
              {/* ModalPopUp */}
              {
                showModalEPC && <ModalPopUp
                  modalShow={showModalEPC}
                  getUploadedData={getUploadedData}
                  modalAction={'upload'}
                  setModalShowFunc={setShowModalEPCFunc}
                  uploadedDataFieldName="epc"
                  parentPath="new-developments"
                  format_text=".png, .jpg, .jpeg, .webp, .pdf"
                  accept_format={{
                    'image/*': ['.jpeg', '.png', '.jpg', '.webp'],
                    'application/pdf': ['.pdf']
                  }}
                />
              }
              {/* UploadedItemsTable */}
              <StatcTable
                menu="New Developments"
                parentPath="file"
                allItems={allUploadedItems.filter(item => (item.field === 'epc'))}
                getUploadedData={getUploadedData}
              />
              <Row>
                <Col lg={6} className="d-flex">
                  <ConfirmOnExitComp {...props} />
                  <div className="ms-3">
                    <Button className="btn-primary-dashboard" onClick={(event) => MoveToTab(event, "3")}>Previous</Button>
                  </div>
                </Col>
                <Col lg={6} className="d-flex justify-content-end">
                  <Button className="btn-primary-dashboard" onClick={(event) => MoveToTab(event, "5")}>Next</Button>
                  <div className="text-end ms-3">
                    <Button type="submit" className="btn-primary-dashboard">Update</Button>
                  </div>
                </Col>
              </Row>
            </Tab>
            <Tab eventKey={"5"} title="Brochure">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <p>The order of the assets below is the order they will be shown on the website.</p>
                <div className="text-end">
                  <Button className="btn-primary-dashboard" onClick={() => showModalBrochureFunc()}>Add</Button>
                </div>
              </div>
              {/* ModalPopUp */}
              {
                showModalBrochure && <ModalPopUp
                  modalShow={showModalBrochure}
                  getUploadedData={getUploadedData}
                  modalAction={'upload'}
                  setModalShowFunc={setShowModalBrochureFunc}
                  uploadedDataFieldName="brochure"
                  parentPath="new-developments"
                  format_text=".pdf"
                  accept_format={{
                    'application/pdf': ['.pdf']
                  }}
                />
              }
              {/* UploadedItemsTable */}
              <StatcTable
                menu="New Developments"
                parentPath="file"
                allItems={allUploadedItems.filter(item => (item.field === 'brochure'))}
                getUploadedData={getUploadedData}
              />
              <Row>
                <Col lg={6} className="d-flex">
                  <ConfirmOnExitComp {...props} />
                  <div className="ms-3">
                    <Button className="btn-primary-dashboard" onClick={(event) => MoveToTab(event, "4")}>Previous</Button>
                  </div>
                </Col>
                <Col lg={6} className="d-flex justify-content-end">
                  <Button className="btn-primary-dashboard" onClick={(event) => MoveToTab(event, "6")}>Next</Button>
                  <div className="text-end ms-3">
                    <Button type="submit" className="btn-primary-dashboard">Save</Button>
                  </div>
                </Col>
              </Row>
            </Tab>
            <Tab eventKey={"6"} title="Properties">
              {
                props?.editItem.id && <CommonTableComp
                  menu="Properties"
                  parentPath="child-properties"
                  pageType="List"
                  additionalParams={{
                    endPointName: "child-properties.getAll",
                    query: `crm_provider=property_uploader&deleteAttempt=0&department=new_developments_residential&_where[_or][0][new_development_null]=true&_where[_or][1][new_development]=${props?.editItem.id}`
                  }}
                  childProperty={{
                    update: true,
                    new_development: props?.editItem.id,
                    childProperties: props?.editItem?.child_properties
                  }}
                  tableParams={{
                    selectableRows: true,
                    onSelectedRowsChange: ({ selectedRows }) => selectedChildProperties({ selectedRows })
                  }}
                  isChildReload={isChildReload}
                />
              }
              <br />
              <Row>
                <Col lg={6} className="d-flex">
                  <ConfirmOnExitComp {...props} />
                  <div className="ms-3">
                    <Button className="btn-primary-dashboard" onClick={(event) => MoveToTab(event, "5")}>Previous</Button>
                  </div>
                </Col>
                <Col lg={6} className="d-flex justify-content-end">
                  <div className="text-end ms-3">
                    <Button type="submit" className="btn-primary-dashboard">Submit</Button>
                  </div>
                </Col>
              </Row>
            </Tab>
          </Tabs>
        </section>
      </Form>
    </>
  )
}

const EditNewDevelopment = (props) => {
  return <CommonEditItemComp PuFormControlsEdit={
    (HandleSubmitFunc, SetPublishActionAvailability, item) => {
      return (
        <>
          <EditFormControls
            {...props} HandleSubmitFunc={HandleSubmitFunc}
            editItem={{
              ...item, address1: item.street_name,
              town: item.area, sub_county: item.district, county: item.town
            }}
            SetPublishActionAvailability={SetPublishActionAvailability}
          />
        </>
      )
    }
  } {...props} DisableDefaultFormAction={true} />
}

export default EditNewDevelopment;